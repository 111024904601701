.cont{
  margin: 1%;
}
.tit-head{
  text-decoration: underline 3px;
  text-transform: capitalize;
}
.cont-text{
  width: 100vw;
}

.des-p{
  padding: 0 5% 1vw 1%;
  font-size: larger;
}

#cont-img{
	max-width: 100vw;
  min-width: 40vw;
  max-height: 30vw;
  min-height: 20vw;
	border-radius: 100px;
	margin: 0 3vw 2vw 1vw;
}

@media (max-width:620px) {
  #cont-img{
    width: 99vw;
    min-height: 70vw;
  }
}