.contact-box{
	background-color: #ffffffc0;
    backdrop-filter: blur(4.4px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)
                ,inset 5px 0px 40px 0px #fff;
    border: 1px solid rgba(241, 241, 253, 0.99);
	color: black;
    border-radius: 30px;
    margin: 0% auto;
    z-index: 1000;
    padding: 1% 3%;
    width: 100%;
    margin: 10vh auto;
}
.contact-cont{
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 10%;
}

.bi-facebook, .bi-facebook:hover, .bi-whatsapp, .bi-whatsapp:hover, .bi-youtube, .bi-youtube:hover, .bi-phone, .bi-phone:hover, .bi-linkedin, .bi-linkedin:hover, .bi-github, .bi-github:hover{
	font-size: 10em;
	color: blue;
}
.bi-whatsapp, .bi-whatsapp:hover{
	color: green;
}

.bi-youtube, .bi-youtube:hover{
	color: red;
}

.bi-phone, .bi-phone:hover{
	color: white;

}


.bi-telephone-fill:hover{
	color: black;
}


.bi-linkedin, .bi-linkedin:hover{
	color: blue;
}


.bi-github, .bi-github:hover{
	color: gray;
}