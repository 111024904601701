body{
    padding-bottom: 5em;
}
.mainsection{
    display: flex;
    align-items: center;
    gap: 3%;
}
.mainimg{
    border-radius: 50%;
    width: 10em;
    height: 10em;
    object-fit: cover;
    transition: 3s;
    animation: bluration 2s infinite;
    transition: 2s;

    }
.mainimg:hover{
    animation: none;
}
.infoimgsscreen{
    width: 100%;
    height: 25vw;
    transition: 2s;
}
.sacodymoti{
    text-align: center;
    margin: 2% 0%;
}
.sacodymotides{
    color:green;
    margin: 1%;
    font-weight: bolder;
    font-size: 0.8em;
}

.indict{
    width: 40%;
    height: 30%;
    margin: 0% 2%;
}

.stackindictor{
    position: absolute;
    right: -10%;
    top: 0%;
    bottom: 0%;
}
.indict > .carousel-indicators{
    flex-direction: row;
    position: absolute;
    right: 0%;
}
.indict > .carousel-indicators > button, .stackimgr, .stackimgl{
    width: 10px!important;
    height: 10px!important;
    background-color: transparent !important;
    border-radius: 50%;
    border: 5px solid #191c76 !important;
}
.stackindictor{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.stackimgr, .stackimgl{
    right: -20%;
    width: 4vw!important;
    height: 4vw!important;
    border: none!important;
}
.carousel-fade{
    z-index: 0;
}
.triinfoimgsscreen{
    width: 30vw;
    height: 20vw;
    transition: 2s;
}

.triimagebox{
    text-align: center;
    transform-origin: center;
    margin: 0% 10%;
    animation: reiimageboxanimate 20s 0ms infinite cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
#triimg{
    width: 15vw;
    height: 15vw;
    border-radius: 50%;
    animation: shadowani 10s 0ms infinite cubic-bezier(0.25, 0.46, 0.45, 0.94);
    box-shadow: 5px 5px 20px 5px black,
                -5px -5px 20px 5px black;
    padding: 2%;
    margin: 0%;
    transition: 2s;
}

.triimagebox:hover, #triimg:hover{
    animation: none;
}

.stackimgbox{
    position: relative;
    width: fit-content;
    margin: 0% 5%;
}

.stackimgr, .stackimgl{
    transition: 2s;   
}


#caru img{
    width: 100%;
    opacity: 0.5;
}
.carudes{
    font-size: 6vw;
    z-index: 1000;
    font-weight: 1000;
}
.carudesdown{
    z-index: 1000;
    font-size: 1.5em;
}
.mergeus{
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}
.other{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0%;
}



@media (max-width:560px) {
    .indict > .carousel-indicators{
    display: none;
}
}

@keyframes reiimageboxanimate {
    0%{
        transform: rotate(0deg) translateY(10px);
    }
    50%{
        transform: rotate(360deg) translateY(0px);
    }
    100%{
        transform: rotate(0deg) translateY(10px);
    }
}

@keyframes shadowani {
    0%{
        box-shadow: 5px 5px 20px 5px #eee,
                -5px -5px 20px 5px #eee;
        transform: rotate(0deg) translateY(10px);
    }
    50%{
        box-shadow: 5px 5px 20px 5px black,
                -5px -5px 20px 5px black;
        transform: rotate(360deg) translateY(0px);
    }
    100%{
        box-shadow: 5px 5px 20px 5px #eee,
                -5px -5px 20px 5px #eee;
        transform: rotate(0deg) translateY(10px);
    }
}

@keyframes bluration {
    0%{
        filter: blur(0px);
    }
    50%{
        filter: blur(30px);
    }
    100%{
        filter: blur(0px);
    }
}