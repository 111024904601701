.own{
    width: 45%;
    margin: 0 auto;
}

#programmer{
    border:.5rem solid #6e6e6e;
    margin: 2%;
    background-color: black;
    text-align: center;
    color: white;   
    transition: 2s;
}

#programmer > h3{
    text-transform: capitalize;
}

#prog-img{
    width:  90%;
    height: 0px;
    border: 2px solid green;
    object-fit: cover;
    object-position: top;
    -o-object-position: top;
    clip-path: circle(50%);
    transition: 2s;
}

/* hover event */
#programmer:hover #prog-img{
    height:50vh;
}
#programmer:hover{
    box-shadow: 10px 10px 10px white;
}

@media (max-width:767px) {
.own{
    width: 100%;
    margin: 0 auto;
}
.own > img{
    object-position: top;
}
}